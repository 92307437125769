<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :bulkRemoveOption="false"
      :showDetailComponent="componentForDetails"
    />
  </div>
  <div v-else-if="!isDataLoaded && table_properties" class="p-10">
    <datatable-detail :table_properties="table_properties"></datatable-detail>
    <v-skeleton-loader class="pt-5" type="table"></v-skeleton-loader>
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTable from "@/own/components/DataTable.vue";
import {
  // EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/requests.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ShowDetails from "@/own/components/datahub/requests/ShowDetails.vue";
export default {
  name: "Requests",
  components: {
    DataTable,
    DatatableDetail,
  },
  data: () => ({
    componentForDetails: ShowDetails,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      // EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getREQUESTSTableFilters",
      getTableState: "getREQUESTSTableState",
      getTableData: "getREQUESTSTableData",
      getTableHeaders: "getREQUESTSTableHeaders",
      getTableProperties: "getREQUESTSTableProperties",
      getTableExportUrl: "getREQUESTSExportUrl",
      getTablePermissions: "getREQUESTSTablePermissions",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    table_properties: function () {
      return this.$store.getters.getREQUESTSTableProperties;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
